import { httpLeasing } from "@/services/http-clients";
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import queryString from "query-string";
import { AxiosError, AxiosProgressEvent } from "axios";

//#region Get Leasing Orders

export interface GetLeasingOrdersParams {
  status: string;
  search?: string;
  page: string;
  size: number;
}

export interface LeasingOrderItem {
  provider_name_fa: string;
  parent_id: string | undefined;
  uuid: string;
  id: number;
  state: IOrderDynamicStatesResponse;
  amount: number;
  is_rejected: boolean;
  customer: {
    full_name: string;
    national_id: string;
    phone_number: string;
  } | null;
  provider: {
    name: string;
    name_fa: string;
    minimum_amount: number;
    maximum_amount: number;
    loan_interest_rate: number;
    instalment_count: string[];
    instalment_period: string[];
  };
  created_at: string;
  instalment_month_count: number;
}

export interface GetLeasingOrdersResponse {
  items: LeasingOrderItem[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

type UpdateLeasingOrder = {
  id: string;
  customer?: {
    first_name?: string;
    last_name?: string;
    national_code?: string;
  };
  package?: {
    provider_id?: number;
    instalment_month_count?: number;
  };
};

const getLeasingOrders = async (
  status: string[],
  page: number,
  search?: string,
  category?: string | undefined,
  provider?: string[],
  cities?: string[],
  orderStart?: string,
  orderEnd?: string,
  noteStart?: string,
  noteEnd?: string,
  isDocumentsRejected?: string,
  channel?: string,
  followUpDate?: string
) => {
  return httpLeasing<GetLeasingOrdersResponse>({
    method: "GET",
    url: `/api/v1/orders/assistant-orders/?${queryString.stringify({
      state_slug: status,
      page: page.toString(),
      size: "10",
      search: search || "",
      note_category: category,
      provider: provider,
      cities: cities,
      order_created_start_date: orderStart,
      order_created_end_date: orderEnd,
      note_created_start_date: noteStart,
      note_created_end_date: noteEnd,
      is_documents_rejected: isDocumentsRejected,
      channel: channel,
      follow_up_date: followUpDate
    })}`
  });
};

const updateLeasingOrder = ({ id, ...data }: UpdateLeasingOrder) => {
  return httpLeasing({
    method: "PATCH",
    url: `/api/v1/orders/assistant-orders/${id}/`,
    data
  });
};

export const useUpdateLeasingOrder = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLeasingOrder>>,
    AxiosError<unknown>,
    UpdateLeasingOrder
  >
) => {
  return useMutation({ ...options, mutationFn: updateLeasingOrder });
};

// export const useCreatePosePayment = (
//   options?: UseMutationOptions<
//     Awaited<ReturnType<typeof createPosePayment>>,
//     AxiosError<unknown>,
//     CreatePosePaymentParams
//   >
// ) => {
//   return useMutation({ ...options, mutationFn: createPosePayment });
// };

const getLeasingOrdersKey = "getLeasingOrders";

export const useGetLeasingOrders = (
  status: string[],
  pageParam: number,
  search?: string,
  category?: string,
  provider?: string[],
  cities?: string[],
  orderStart?: string,
  orderEnd?: string,
  noteStart?: string,
  noteEnd?: string,
  isDocumentsRejected?: string,
  channel?: string,
  followUpDate?: string
) => {
  return useInfiniteQuery({
    queryKey: [
      getLeasingOrdersKey,
      status,
      pageParam,
      search,
      category,
      provider,
      cities,
      noteStart,
      noteEnd,
      orderStart,
      orderEnd,
      isDocumentsRejected,
      channel,
      followUpDate
    ],
    queryFn: ({ pageParam = 1 }) => {
      return getLeasingOrders(
        status,
        pageParam,
        search,
        category,
        provider,
        cities,
        orderStart,
        orderEnd,
        noteStart,
        noteEnd,
        isDocumentsRejected,
        channel,
        followUpDate
      ).then((res) => {
        return res.data;
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.page !== lastPage.pages && lastPage.pages
        ? lastPage.page + 1
        : undefined;
    }
  });
};

//#endregion

//#region Get Leasing status
// export interface GetLeasingStatusResponse {
//   [key: string]: string;
// }

// const getLeasingStatus = async () => {
//   return httpLeasing<GetLeasingStatusResponse>({
//     method: "GET",
//     url: `/api/v1/orders/assistant-orders/status/`
//   }).then((res) => res.data);
// };

// const getLeasingStatusKey = "getLeasingStatus";

// export const useGetLeasingStatus = (
//   options?: UseQueryOptions<
//     Awaited<ReturnType<typeof getLeasingStatus>>,
//     // unknown,
//     unknown,
//     Awaited<ReturnType<typeof getLeasingStatus>>
//   >
// ) => {
//   return useQuery({
//     queryKey: [getLeasingStatusKey],
//     staleTime: 0,
//     queryFn: () => {
//       return getLeasingStatus();
//     },
//     ...options
//   });
// };

//#endregion

interface GetSearchLeasingParams {
  search?: string;
}

const getSearchLeasingRequest = async (params: GetSearchLeasingParams) => {
  return httpLeasing<GetLeasingOrdersResponse>({
    method: "GET",
    url: `/api/v1/orders/assistant-orders/`,
    params: {
      search: params.search
    }
  });
};

const getSearchLeasingRequestKey = "getSearchLeasingRequest";

export const useGetSearchLeasingRequest = (params: GetSearchLeasingParams) => {
  return useQuery({
    queryKey: [getSearchLeasingRequestKey, params],
    staleTime: 0,
    queryFn: () => {
      return getSearchLeasingRequest(params).then((res) => {
        return res.data;
      });
    }
  });
};

export type PosePaymentsParams = { id: string };
export type PosePaymentsResponse = {
  id: number;
  amount: number;
  image: string;
  created_at: string;
  modified_at: string;
  order: number;
};

const getPosePayments = (params: PosePaymentsParams) => {
  return httpLeasing<PosePaymentsResponse[]>({
    method: "GET",
    url: `/api/v1/orders/assistant-orders/${params.id}/pose-payments/`
  }).then((res) => res.data);
};

export const getPosePaymentsKey = (id: string) => [`post-payments-${id}`];

export const useGetPosePayments = (
  params: PosePaymentsParams,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof getPosePayments>>,
      AxiosError<unknown>,
      Awaited<ReturnType<typeof getPosePayments>>,
      string[]
    >,
    "queryFn" | "queryKey"
  >
) => {
  return useQuery({
    ...options,
    queryFn: () => getPosePayments(params),
    queryKey: getPosePaymentsKey(params.id)
  });
};

type UploadLeasingFileParams = {
  scope: "leasing-pose-payments" | "leasing-documents";
  file: File;
};
export type UploadLeasingFileResponse = {
  file_url: string;
  key: string;
};

const uploadLeasingFile = (
  data: UploadLeasingFileParams,
  onUploadProgressCb?: (percentage: AxiosProgressEvent) => void
) => {
  const formData = new FormData();
  formData.append("file", data.file, data.file.name);
  formData.append("scope", data.scope);
  return httpLeasing<UploadLeasingFileResponse>({
    method: "POST",
    url: "/api/v1/common/files/",
    data: formData,
    onUploadProgress(progressEvent) {
      if (onUploadProgressCb) onUploadProgressCb(progressEvent);
    }
  });
};

export const useUploadLeasingFile = (
  onUploadProgressCb?: (percentage: AxiosProgressEvent) => void,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadLeasingFile>>,
    AxiosError<unknown>,
    UploadLeasingFileParams
  >
) => {
  return useMutation({
    ...options,
    mutationFn: (data: UploadLeasingFileParams) =>
      uploadLeasingFile(data, onUploadProgressCb)
  });
};

type CreatePosePaymentParams = {
  amount: number;
  image: string;
  paid_at: string;
  id: string;
};

const createPosePayment = ({ id, ...data }: CreatePosePaymentParams) => {
  return httpLeasing({
    method: "POST",
    url: `/api/v1/orders/assistant-orders/${id}/pose-payments/`,
    data
  });
};

export const useCreatePosePayment = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof createPosePayment>>,
    AxiosError<unknown>,
    CreatePosePaymentParams
  >
) => {
  return useMutation({ ...options, mutationFn: createPosePayment });
};

type DeletePosePaymentParams = { id: string; imageId: string };

const deletePosePayment = ({ id, ...data }: DeletePosePaymentParams) => {
  return httpLeasing({
    method: "DELETE",
    url: `/api/v1/orders/assistant-orders/${id}/pose-payments/${data.imageId}/`
  });
};

export const useDeletePosePayment = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePosePayment>>,
    AxiosError<unknown>,
    DeletePosePaymentParams
  >
) => {
  return useMutation({ ...options, mutationFn: deletePosePayment });
};

export interface CustomerValidationType {
  order: number;
  birthdate: string;
  birthplace?: string;
  postal_code?: string;
  address?: string;
}

const customerValidationSubmit = (values: CustomerValidationType) => {
  return httpLeasing({
    method: "POST",
    url: "/api/v1/orders/customer-validation/",
    data: values
  });
};

export const useCustomerValidationSubmit = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof customerValidationSubmit>>,
    AxiosError<unknown>,
    CustomerValidationType
  >
) => {
  return useMutation({ ...options, mutationFn: customerValidationSubmit });
};

export interface IOrderDynamicStatesResponse {
  id: number;
  slug: string;
  name_customer_fa: number;
  name_fa: string;
  priority: number;
}

export const getOrderDynamicStates = (orderId?: string) => {
  return httpLeasing<IOrderDynamicStatesResponse[]>({
    url: `/api/v1/orders/states/`,
    params: {
      channels: "chassis",
      order_id: orderId ?? undefined
    }
  });
};
export const useGetOrderDynamicStates = (orderId?: string) => {
  return useQuery({
    queryFn: () => getOrderDynamicStates(orderId).then((res) => res.data),
    queryKey: [`/api/v1/orders/states/`, orderId],
    staleTime: 0,
    refetchOnMount: false
  });
};

export type StateActionType = "CUSTOM" | "SMS" | "LINK";
export interface LeasingStateActionItem {
  slug: string;
  name_en: string;
  name_fa: string;
  description: string;
  type: StateActionType;
  id: number;
  params: StateActionItemParam;
}
export interface StateActionParamInput {
  type: "text" | "number" | "select" | "date" | "datetime" | "file" | "image";
  name: string;
  title: string;
  required: boolean;
  subtitle?: string;
  placeholder?: string;
  default_value?: string;
  options: { label: string; value: string; priority: number }[];
}
export interface StateActionItemParam {
  open_in_new_tab: boolean;
  button_text: string;
  button_type: "success" | "danger" | "outlined";
  inputs?: StateActionParamInput[];
  disabled: boolean;
  mandatory: boolean;
  default_value?: string;
  description: string;
}

export interface LeasingStateActionParams {
  orderId: string;
  stateId?: string;
  state_slug?: string;
}

export const getOrderStateActions = ({
  orderId,
  stateId
}: LeasingStateActionParams) => {
  return httpLeasing<LeasingStateActionItem[]>({
    url: `/api/v1/orders/assistant-orders/${orderId}/actions/`,
    params: {
      state: stateId ?? undefined
    }
  });
};

// export const useGetOrderStateActions = (
//   params: LeasingStateActionParams,
//   enabled = true
// ) => {
//   return useQuery({
//     queryFn: () =>
//       getOrderStateActions(params.orderId, params.stateId).then(
//         (res) => res.data
//       ),
//     enabled: enabled,
//     queryKey: [
//       `/api/v1/orders/states/`,
//       params.orderId,
//       params.state_slug,
//       params.stateId
//     ],
//     staleTime: 0,
//     refetchOnMount: false
//   });
// };

export const useGetMandatoryActions = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof getOrderStateActions>>,
    AxiosError<unknown>,
    LeasingStateActionParams
  >
) => {
  return useMutation({ ...options, mutationFn: getOrderStateActions });
};

export const useGetOrderStateActions = (
  params: LeasingStateActionParams,
  enabled = true
) => {
  return useQuery({
    queryFn: () => getOrderStateActions(params).then((res) => res.data),
    enabled: enabled,
    queryKey: [
      `/api/v1/orders/backoffice-orders/actions`,
      params.orderId,
      params.state_slug,
      params.stateId
    ],
    staleTime: 0,
    refetchOnMount: false
  });
};

export interface ExecuteStateActionBody {
  orderId: number;
  actionId: number;
  body?: {
    inputs: Record<string, string | number>;
  };
}

export interface ExecuteStateActionResponse {
  output: {
    error: boolean;
    data: {
      url: string;
    };
    message: string;
  };
}

export const executeStateAction = (params: ExecuteStateActionBody) => {
  return httpLeasing<ExecuteStateActionResponse>({
    method: "post",
    url: `/api/v1/orders/${params.orderId}/actions/${params.actionId}/execute/`,
    data: params.body
  }).then((res) => res.data);
};

export const useExecuteStateAction = () => {
  return useMutation({
    mutationFn: (data: ExecuteStateActionBody) => executeStateAction(data)
  });
};
