import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import useAppSelector from "@/hooks/useAppSelector";
import { useCallback } from "react";

interface NotificationSlice {
  permissionState: NotificationPermission;
}

const initialState: NotificationSlice = {
  permissionState: Notification.permission
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationState(state, action: PayloadAction<NotificationPermission>) {
      state.permissionState = action.payload;
    }
  }
});

export const notificationReducer = notificationSlice.reducer;
export const notificationActions = notificationSlice.actions;

export const useNotificationState = () => {
  const dispatch = useDispatch();
  const { permissionState } = useAppSelector((state) => state.notification);

  const setNotificationState = useCallback(
    (value: NotificationPermission) => {
      dispatch(notificationActions.setNotificationState(value));
    },
    [dispatch]
  );

  return {
    permissionState,
    setNotificationState
  };
};
