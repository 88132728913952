import { DEVICE_ID, FCM_TOKEN } from "./consts";
import { authorizationCookieKey } from "./env";

export const setAccessTokenToLocalStorage = (token: string) => {
  localStorage.setItem(authorizationCookieKey, token);
};

export const getAccessTokenFromLocalStorage = () => {
  return localStorage.getItem(authorizationCookieKey) ?? "";
};

export const setFCMTokenToLocalStorage = (fcmToken: string) => {
  localStorage.setItem(FCM_TOKEN, fcmToken);
};

export const getFCMTokenFromLocalStorage = () => {
  return localStorage.getItem(FCM_TOKEN);
};

export const setDeviceIdToLocalStorage = (deviceId: string) => {
  localStorage.setItem(DEVICE_ID, deviceId);
};

export const getDeviceIdFromLocalStorage = () => {
  return localStorage.getItem(DEVICE_ID);
};

export const setValueToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getValueFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) || undefined;
};

export const removeValueFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
